<template>
  <div>
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          size="sm"
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click="openModalOblige"
        >
          <font-awesome-icon icon="file-export" />
        </div>
      </template>
      <span>Télecharger xls facture</span>
    </v-tooltip>
    <!-- Extract OBLIGEE FACTURE Modal -->
    <v-dialog
      v-model="dialog"
      max-width="750px"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-header-vue-oblige"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"> Générer un xls</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('dialog')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card mt-2">
          <v-row class="mt-2">
            <v-col cols xs="12" sm="12" md="12">
              <v-select
                :items="computedListModelToExtract"
                v-model="selectedModel"
                label="Modèle"
                dense
                outlined
                :rules="[v => !!v || 'Modèle obligatoire']"
                required
                class="msg-error"
                validate-on-blur
                :menu-props="{ bottom: true, offsetY: true }"
                color="#704ad1"
                item-color="#704ad1"
              ></v-select>
            </v-col>
          </v-row>
          <v-label class="title-tabel-global mt-3 ml-2"
            >Type de facture :</v-label
          >
          <!-- TYPE FACTURE -->
          <v-checkbox
            label="Tous"
            color="#704ad1"
            hide-details
            v-model="computedCheckAll"
            @change="changeAllCheckTypes"
          ></v-checkbox>

          <div
            v-if="getTypeListFactureObligee.length"
            class="confirm-fac-check-oblige"
          >
            <div
              v-for="(option, index) in getTypeListFactureObligee"
              :key="index"
              class="mr-3"
            >
              <v-checkbox
                color="#704ad1"
                hide-details
                v-model="type"
                @change="
                  type.length == getTypeListFactureObligee.length
                    ? (checkAllType = true)
                    : (checkAllType = false)
                "
                :value="option.value"
                :label="option.text"
              >
              </v-checkbox>
            </div>
          </div>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div
            v-if="errorAppeliPaiementThObligeeExportXls"
            class="message-error-modal"
          >
            <ul
              v-if="Array.isArray(errorAppeliPaiementThObligeeExportXls)"
              class="mb-0"
            >
              <li
                v-for="(e, index) in errorAppeliPaiementThObligeeExportXls"
                :key="index"
              >
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ errorAppeliPaiementThObligeeExportXls }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="handleExport"
            :disabled="chargementAppelPaiementThObligeeExportXlsFacture"
            :loading="chargementAppelPaiementThObligeeExportXlsFacture"
            :class="{
              loader: chargementAppelPaiementThObligeeExportXlsFacture
            }"
          >
            Générer
          </v-btn>
          <v-btn text @click.prevent="hideModal('dialog')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Extract OBLIGEE FACTURE Modal -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    resetModalExportFacture: { required: true }
  },
  data() {
    return {
      type: [],
      selectedModel: null,
      error: null,
      dialog: false
    };
  },
  methods: {
    ...mapActions(['getAllModelExports']),
    openModalOblige() {
      if (this.computedListModelToExtract.length) {
        this.selectedModel = this.computedListModelToExtract[0]?.value;
      }
      this.dialog = true;
    },
    handleExport() {
      this.error = null;
      if (this.type.length == 0) {
        this.error = 'Il faut sélectionner au moins un type';
      } else {
        this.$emit('exportFactureXls', this.selectedModel, this.type);
      }
    },
    resetModal(ref) {
      this.hideModal(ref);
      this.selectedModel = 'vertical';
    },
    hideModal(ref) {
      this[ref] = false;
    },
    changeAllCheckTypes() {
      if (this.computedCheckAll == false) {
        this.type = this.getTypeListFactureObligee.map(item => item.value);
      } else {
        this.type = [];
      }
    }
  },
  computed: {
    ...mapGetters([
      'chargementAppelPaiementThObligeeExportXlsFacture',
      'errorAppeliPaiementThObligeeExportXls',
      'getTypeListFactureObligee',
      'models'
    ]),
    computedListModelToExtract() {
      if (this.models.length) {
        return this.models.map(item => {
          return {
            text: item.name,
            value: item.id
          };
        });
      }
      return [];
    },
    computedCheckAll() {
      if (this.type.length == this.getTypeListFactureObligee.length) {
        return true;
      }
      return false;
    }
  },
  watch: {
    resetModalExportFacture() {
      this.resetModal('dialog');
    }
  },
  mounted() {
    this.type = this.getTypeListFactureObligee.map(item => item.value);
    this.getAllModelExports();
  }
};
</script>

<style scoped lang="scss">
.new-color-export-facture {
  background-color: #0e0c5e;
}
</style>
<style lang="scss">
// .test {
//   .custom-checkbox {
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     width: 100%;
//     align-items: flex-start;
//   }
// }
</style>
